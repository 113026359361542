import { Box } from '@stars-ecom/shared-atoms-ui'
import { DataLayerUtils, OfferUtils, ProductLayout } from '@stars-ecom/shared-organisms-ui'
import { TimedContent } from '@stars-ecom/shared-organisms-ui/src/utils'
import { getMultipaymentData } from '@stars-ecom/shared-organisms-ui/src/utils/payment'
import { graphql } from 'gatsby'
import capitalize from 'lodash/capitalize'
import compact from 'lodash/compact'
import get from 'lodash/get'
import take from 'lodash/take'
import uniqBy from 'lodash/uniqBy'
import React from 'react'
import { Helmet } from 'react-helmet'

import { lengthLimiter, siteFormatter } from '../utils/metaTitleFormatter'
import { simplifyGQLResult } from '../utils/strapi'

const ProductPage = (props) => {
  const { pageContext, location, data } = props

  const { collection, tvShow, reviewAvg, loyaltyCardImage, productLayout } = pageContext
  const product = {
    ...get(data, 'vendure.product', {}),
    reviewAvg,
    customFields: {
      ...get(data, 'vendure.product.customFields', {}),
      legalMentions: compact(
        TimedContent.filter(
          get(data, 'vendure.currentSpecialMentions.items', []).map((i) => {
            if (i.useLegalMention) {
              return { content: i.legalMention }
            }
            return null
          })
        )
      ),
      specialMentions: compact(
        TimedContent.filter(
          get(data, 'vendure.currentSpecialMentions.items', []).map((i) => {
            if (i.useSecondaryMention) {
              return { content: i.secondaryMention, color: i.secondaryMentionColor }
            }
            return null
          })
        )
      ),
      upSell: OfferUtils.removeNoOfferProducts(get(data, 'vendure.upSell.items', [])),
      crossSell: take(
        OfferUtils.removeNoOfferProducts(
          uniqBy(
            [
              ...get(data, 'vendure.crossSell.items', []),
              ...get(data, 'vendure.crossSellAdd.items', [])
            ],
            (p) => p.id
          )
        ),
        16
      )
    },
    blogArticles: [
      ...simplifyGQLResult(get(data, 'strapi.products.data.0.attributes.blog_articles', []))
    ]
  }

  const multipaymentData = getMultipaymentData(get(data, 'vendure.paymentMethods.items', []))

  DataLayerUtils.addEvent({
    event: 'newEcomDatalayer',
    product: {
      id: product?.id,
      externalCode: product?.customFields?.externalCode,
      name: product?.name,
      imageUrl: product?.assets
        ?.find((a) => a?.mimeType === 'image/jpeg')
        ?.customFields?.sourceOrigin.replace('?x-id=GetObject', ''),
      price:
        OfferUtils.getCurrentProductOffer(product, 'STANDARD', process.env.GATSBY_API_CHANNEL_ID)
          .price / 100,
      description: product?.description,
      brand: product?.customFields?.brand,
      collection: collection?.name
    }
  })

  const metaTitle = `${capitalize(get(product, 'customFields.searchKeywords.0', ''))} - ${
    product.name
  }`

  return (
    <Box w="full" style={{ backgroundColor: 'white' }}>
      <Helmet
        bodyAttributes={{
          class: 'chakra-ui-light'
        }}>
        <meta charSet="utf-8" />
        <title>
          {product?.customFields?.metaTitle ||
            `${lengthLimiter(metaTitle)} - ${siteFormatter(pageContext?.website?.title)}`}
        </title>
        <meta name="description" content={product?.customFields?.metaDescription} />
        <meta name="keywords" content={product?.customFields?.metaKeywords?.join(', ')} />
      </Helmet>

      <ProductLayout
        product={product}
        collection={collection}
        tvShow={tvShow}
        reviewLink={pageContext.website.reviewLink}
        loyaltyCardImage={loyaltyCardImage}
        productLayout={productLayout}
        location={location}
        websiteTitle={pageContext?.website?.title}
        multipaymentData={multipaymentData}
      />
    </Box>
  )
}

export const query = graphql`
  query product(
    $idProduct: ID!
    $idProductStr: String
    $websiteId: ID
    $crossSellIds: [String!]
    $crossSellAddIds: [String!]
    $upSellIds: [String!]
  ) {
    strapi {
      products(filters: { idProduct: { eq: $idProductStr } }) {
        data {
          id
          attributes {
            externalCode
            idProduct
            name
            blog_articles(filters: { websites: { id: { eq: $websiteId } } }) {
              data {
                attributes {
                  title
                  hook
                  path
                  media {
                    data {
                      attributes {
                        url
                        mime
                        image {
                          childImageSharp {
                            gatsbyImageData(placeholder: BLURRED, width: 830, height: 496)
                          }
                        }
                      }
                    }
                  }
                  thumbnail_image {
                    data {
                      attributes {
                        url
                        mime
                        image {
                          childImageSharp {
                            gatsbyImageData(placeholder: BLURRED, width: 830, height: 496)
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    vendure {
      product(id: $idProduct) {
        id
        slug
        name
        description
        optionGroups {
          name
        }
        assets {
          id
          source
          mimeType
          customFields {
            sourceOrigin
          }
          hugeImage: image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 900, height: 720)
            }
          }
          largeImage: image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 450, height: 360)
            }
          }
          smallImage: image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 100, height: 80)
            }
          }
          image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 215, height: 172)
            }
          }
        }
        featuredAsset {
          id
          source
          mimeType
          customFields {
            sourceOrigin
          }
          image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 215, height: 172)
            }
          }
          largeImage: image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 450, height: 360)
            }
          }
          smallImage: image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 100, height: 80)
            }
          }
        }
        facetValues {
          id
          facet {
            code
            name
          }
          name
          customFields {
            color
            visible
          }
        }
        customFields {
          externalCode
          urlKey
          shortDescription
          productGroupCode
          metaKeyword
          metaTitle
          metaDescription
          hook
          argContext
          refundWarranty
          brand
          searchKeywords
          shippingContent
          warranty
          barCode
          attachments {
            id
            source
            mimeType
            customFields {
              sourceOrigin
              category
            }
          }
        }
        variants {
          id
          name
          stockLevel
          options {
            id
            code
            name
            group {
              name
            }
          }
          customFields {
            offers
            externalCode
          }
        }
      }

      currentSpecialMentions(productId: $idProduct) {
        items {
          id
          legalMention
          secondaryMention
          secondaryMentionColor
          startDate
          endDate
          useLegalMention
          useSecondaryMention
        }
      }

      crossSell: products(options: { filter: { id: { in: $crossSellIds } } }) {
        items {
          id
          name
          featuredAsset {
            id
            source
            mimeType
            customFields {
              sourceOrigin
            }
            image {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, width: 215, height: 172)
              }
            }
          }
          customFields {
            urlKey
            published
            productGroupCode
          }
          variants {
            id
            customFields {
              offers
              externalCode
              productType
            }
          }
        }
      }

      crossSellAdd: products(options: { filter: { id: { in: $crossSellAddIds } } }) {
        items {
          id
          name
          featuredAsset {
            id
            source
            mimeType
            customFields {
              sourceOrigin
            }
            image {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, width: 215, height: 172)
              }
            }
          }
          customFields {
            urlKey
            published
            productGroupCode
          }
          variants {
            id
            customFields {
              offers
              externalCode
              productType
            }
          }
        }
      }

      upSell: products(options: { filter: { id: { in: $upSellIds } } }) {
        items {
          id
          name
          featuredAsset {
            id
            source
            mimeType
            customFields {
              sourceOrigin
            }
            image {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, width: 215, height: 172)
              }
            }
          }
          variants {
            id
            options {
              id
              code
              name
              group {
                name
              }
            }
            customFields {
              offers
              externalCode
            }
          }
          customFields {
            urlKey
            published
            productGroupCode
          }
        }
      }

      paymentMethods {
        items {
          id
          name
          code
          customFields {
            nbDebit
            applicationFee
          }
          checker {
            args {
              name
              value
            }
          }
        }
      }
    }
  }
`

ProductPage.pageType = 'product'

export default ProductPage
